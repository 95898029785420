@import '~bootstrap/dist/css/bootstrap.css'
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap')

@import '@angular/material/theming'
@include mat-core()

body
  overflow-x: hidden
  margin: 0
  padding: 0
  font-family: Lato

